.embeddedServiceSidebar {
	z-index: 1002 !important;

	/* Hide the Company and LeadSource fields; their data will be pre-populated and will be sent
	   along with the user-entered data to SF. */
	& div.fieldList div:nth-child(n + 7) {
		display: none;
	}

	& .buttonWrapper {
		padding-top: 0 !important;
	}

	& .embeddedServiceSidebarForm > .fieldList {
		margin-bottom: 0;
	}

	& .sidebarHeader {
		background-color: var(--theme-grey-darker) !important;

		& .minimizeButton {
			margin-bottom: 12px;
		}
	}

	&.layout-docked .dockableContainer {
		/* make room for the form controls so they fit without scrolling */
		max-height: 510px !important;

		/* move the chat modal to the left so the feedback button doesn't overlap it */
		right: 55px !important;
	}

	& .uiInputDefaultError {
		display: none !important;
	}

	& .chatActionButton.Send {
		margin-left: 2px !important;
	}
}

/* make the mobile chat button smaller */
.embeddedServiceHelpButton {
	& .uiButton {
		min-width: 0 !important;
	}
}

/* apply default chat button style on larger screens */
@media (--breakpoint-not-small) {
	.embeddedServiceHelpButton {
		& .uiButton {
			min-width: 12em !important;
		}
	}
}

.rich-menu-items > li {
	counter-increment: li;
	display: flex;
	align-items: center;
	padding-left: 0.25em;
	position: relative;
}

.rich-menu-item.embeddedServiceLiveAgentStateChatRichItem::before,
.rich-menu-itemOptionIsClicked.embeddedServiceLiveAgentStateChatRichItem::before {
	content: counter(li);
	position: absolute;
	left: 0.3em;
}

.embeddedServiceLiveAgentStateChatMenuMessage .rich-menu-item,
.embeddedServiceLiveAgentStateChatMenuMessage .rich-menu-itemOptionIsClicked {
	text-align: left !important;
	margin-left: 1em !important;
}

/* hide chat button in print */
@media print {
	.helpButton {
		display: none;
	}
}
