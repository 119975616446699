.placeholder {
	position: static;
	overflow: hidden;
	animation: wave 2s linear;
	animation-iteration-count: infinite;
	background-color: #fff;
	background-image: linear-gradient(to right, rgb(0 0 0 / 10%) 0, rgb(0 0 0 / 20%) 15%, rgb(0 0 0 / 10%) 30%);
	background-size: 75rem 100%;
}

@keyframes wave {
	0% {
		background-position: -75rem 0;
	}

	100% {
		background-position: 75rem 0;
	}
}
