svg {
	fill: currentColor;
}

html {
	font-family: var(--sans-serif);
}

body {
	margin: 0;
	color: var(--theme-grey-darker);
}

/*
* UX wants buttons to always be these specific heights,
* especially when icons/elements are larger than the line height
*/
.input {
	padding-top: 12px;
	padding-bottom: 12px;
}

.input-small {
	padding-top: 6.5px;
	padding-bottom: 6.5px;
}

.input-large {
	padding-top: 17.5px;
	padding-bottom: 17.5px;
}

/* These exist in global because Dynamic Yield client-side templates depend on it */
.star-rated {
	/* prettier-ignore */
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1' viewBox='0 0 512 512'%3E%3Cpath stroke='%23dcdcdc' stroke-width='30' fill='none' d='m439,212l-141,0l-42,-138l-42,138l-141,0l113,86l-44,144l115,-92l115,92l-44,-144l113,-86l0,0l-1,0z'/%3E%3C/svg%3E") repeat no-repeat;
	background-size: 1em;
	height: 1em;
	width: 5em;
	display: inline-block;
}

.star-rating {
	/* prettier-ignore */
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffad00' d='m439,212l-141,0l-42,-138l-42,138l-141,0l113,86l-44,144l115,-92l115,92l-44,-144l113,-86l0,0l-1,0z'/%3E%3C/svg%3E") repeat no-repeat;
	background-size: 1em;
	height: 1em;
	display: block;
}

.disableClickEvents {
	pointer-events: none;
}

/* Doesn't currently exist in tachyons */
.lh-normal {
	line-height: normal;
}

@media (--breakpoint-not-small) {
	.max-vh-75-ns {
		max-height: 75vh;
	}
}

.sticky {
	position: sticky;
}

@media (--breakpoint-not-small) {
	.sticky-ns {
		position: sticky;
	}
}

@media (--breakpoint-medium) {
	.sticky-m {
		position: sticky;
	}
}

@media (--breakpoint-large) {
	.sticky-l {
		position: sticky;
	}
}

@media print {
	body {
		print-color-adjust: exact;
	}

	/* stylelint-disable-next-line selector-id-pattern */
	#kampyleButtonContainer {
		/* hide Medallia button when printing */
		display: none;
	}
}

/*
* Hides Googles reCAPTCHA badge in favor of showing
* a custom message to the user
*/
.grecaptcha-badge {
	visibility: hidden;
}

/*
* Set at 45% to account for margins/paddings
*/
.flexBasisHalf {
	flex-basis: 45%;
}

/* Set flex-basis to zero. Combine with flex-grow-1 to allow items to grow evenly. */
.flex-basis-0 {
	flex-basis: 0;
}

/* Set min-width to minimum content size. */
.minw-minc {
	min-width: min-content;
}

/* Set min-width to maximum content size. */
.minw-maxc {
	min-width: max-content;
}

/*
* page break should always occur before an element with this class
*/
.page-bb {
	page-break-before: always;
	break-before: page;
}

/*
* page break should be avoided inside an element with the class
*/
.page-avoid {
	page-break-inside: avoid;
	break-inside: avoid;
}
