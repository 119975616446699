.rich-text a {
	line-height: 1.5em;
	color: var(--theme-primary);
	text-decoration: none;
	-webkit-tap-highlight-color: rgb(0 0 0 / 10%);
	background: 0 0;
}

.rich-text a:hover {
	text-decoration: underline;
}
