@keyframes slideInLeft {
	from {
		transform: translate3d(-100%, 0, 0);
		visibility: hidden;
	}

	to {
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}
}

.slideInLeft {
	animation-name: slideInLeft;
}

@keyframes slideOutLeft {
	from {
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}

	to {
		visibility: hidden;
		transform: translate3d(-100%, 0, 0);
	}
}

.slideOutLeft {
	animation-name: slideOutLeft;
}

@keyframes slideInRight {
	from {
		transform: translate3d(100%, 0, 0);
		visibility: hidden;
	}

	to {
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}
}

.slideInRight {
	animation-name: slideInRight;
}

@keyframes slideOutRight {
	from {
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}

	to {
		visibility: hidden;
		transform: translate3d(100%, 0, 0);
	}
}

.slideOutRight {
	animation-name: slideOutRight;
}

@keyframes slideInTop {
	from {
		transform: translate3d(0, -100%, 0);
		visibility: hidden;
	}

	to {
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}
}

.slideInTop {
	animation-name: slideInTop;
}

@keyframes slideOutTop {
	from {
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}

	to {
		visibility: hidden;
		transform: translate3d(0, -100%, 0);
	}
}

.slideOutTop {
	animation-name: slideOutTop;
}

@keyframes slideInBottom {
	from {
		transform: translate3d(0, 100%, 0);
		visibility: hidden;
	}

	to {
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}
}

.slideInBottom {
	animation-name: slideInBottom;
}

@keyframes slideOutBottom {
	from {
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}

	to {
		visibility: hidden;
		transform: translate3d(0, 100%, 0);
	}
}

.slideOutBottom {
	animation-name: slideOutBottom;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fade-in {
	animation-duration: 0.5s;
	animation-fill-mode: both;
	animation-name: fadeIn;
}
