.tc1-title {
	white-space: pre-line;
	position: relative;
	height: 1.25em;
	overflow: hidden;

	&::after {
		content: '';
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 50%;
		height: 1.25em;
		background: linear-gradient(to right, rgb(255 255 255 / 0%), white 80%);
	}
}

@supports (-webkit-line-clamp: 1) {
	.tc1-title {
		max-height: 1.25em;
		line-height: 1.25;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		height: inherit;
	}

	.tc1-title::after {
		display: none;
	}
}

@media (--breakpoint-large) {
	.tc1-title-l {
		white-space: pre-line;
		position: relative;
		height: 1.25em;
		overflow: hidden;

		&::after {
			content: '';
			text-align: right;
			position: absolute;
			bottom: 0;
			right: 0;
			width: 50%;
			height: 1.25em;
			background: linear-gradient(to right, rgb(255 255 255 / 0%), white 80%);
		}
	}

	@supports (-webkit-line-clamp: 1) {
		.tc1-title-l {
			max-height: 1.25em;
			line-height: 1.25;
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			height: inherit;
		}

		.tc1-title-l::after {
			display: none;
		}
	}
}

.tc2-title {
	white-space: pre-line;
	position: relative;
	height: 2.5em;
	overflow: hidden;

	&::after {
		content: '';
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 50%;
		height: 1.25em;
		background: linear-gradient(to right, rgb(255 255 255 / 0%), white 80%);
	}
}

@supports (-webkit-line-clamp: 1) {
	.tc2-title {
		max-height: 2.5em;
		line-height: 1.25;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		height: inherit;
	}

	.tc2-title::after {
		display: none;
	}
}

.tc2-copy {
	white-space: pre-line;
	position: relative;
	height: 3em;
	overflow: hidden;

	&::after {
		content: '';
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 50%;
		height: 1.5em;
		background: linear-gradient(to right, rgb(255 255 255 / 0%), white 80%);
	}
}

@supports (-webkit-line-clamp: 1) {
	.tc2-copy {
		max-height: 3em;
		line-height: 1.5;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		height: 100%;
	}

	.tc2-copy::after {
		display: none;
	}
}

.tc3-title {
	white-space: pre-line;
	position: relative;
	height: 3.75em;
	overflow: hidden;

	&::after {
		content: '';
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 50%;
		height: 1.25em;
		background: linear-gradient(to right, rgb(255 255 255 / 0%), white 80%);
	}
}

@supports (-webkit-line-clamp: 1) {
	.tc3-title {
		max-height: 3.75em;
		line-height: 1.25;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		height: inherit;
	}

	.tc3-title::after {
		display: none;
	}
}

@media (--breakpoint-not-small) {
	.tc3-title-ns {
		white-space: pre-line;
		position: relative;
		height: 3.75em;
		overflow: hidden;

		&::after {
			content: '';
			text-align: right;
			position: absolute;
			bottom: 0;
			right: 0;
			width: 50%;
			height: 1.25em;
			background: linear-gradient(to right, rgb(255 255 255 / 0%), white 80%);
		}
	}

	@supports (-webkit-line-clamp: 1) {
		.tc3-title-ns {
			max-height: 3.75em;
			line-height: 1.25;
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			height: inherit;
		}

		.tc3-title-ns::after {
			display: none;
		}
	}
}

.tc3-copy {
	white-space: pre-line;
	position: relative;
	height: 4.5em;
	overflow: hidden;

	&::after {
		content: '';
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 50%;
		height: 1.5em;
		background: linear-gradient(to right, rgb(255 255 255 / 0%), white 80%);
	}
}

@supports (-webkit-line-clamp: 1) {
	.tc3-copy {
		max-height: 4.5em;
		line-height: 1.5;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		height: 100%;
	}

	.tc3-copy::after {
		display: none;
	}
}
