.busy {
	width: 1em;
	height: 1em;
	display: inline-block;
	box-sizing: border-box;
	position: relative;
}

.busy::before {
	border-radius: 50%;
	content: ' ';
	width: 1em;
	height: 1em;
	display: inline-block;
	box-sizing: border-box;
	border: solid 0.15em var(--theme-primary-lighter);
	position: absolute;
	top: 0;
	left: 0;
}

.busy::after {
	border-radius: 50%;
	content: ' ';
	width: 1em;
	height: 1em;
	display: inline-block;
	box-sizing: border-box;
	border-top: solid 0.15em var(--theme-primary);
	border-right: solid 0.15em var(--theme-primary-dark);
	border-bottom: solid 0.15em var(--theme-primary-lighter);
	border-left: solid 0.15em transparent;
	position: absolute;
	top: 0;
	left: 0;
	animation: busy 0.6s ease-in-out infinite;
}

@keyframes busy {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}
